html, body, #root {
  margin: 0;
  height: 100%;
}

#pinContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.panel {
  height: 100%;
  width: 100%;
  position: absolute;
}

.panel.blue {
  background-color: blue;
}

.panel.green {
  background-color: green;
}

.mobile-section {
  width: 100%;
  height: 100vh;
}

.mobile-content {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.carousel .slide {
  background: transparent !important;
}

.carousel .control-dots {
  margin: 0 -15px !important;
}
